import * as buttonImage from '@/assets/img/content/ic_calendar.svg'

$(function(){
  //gnb
  // $(".gnb > ul > li").click(function(){
  //   $(".gnb > ul > li").removeClass("is_active");
  //   $(this).addClass("is_active");
  //   $(".gnb .depth2").stop().slideUp();
  //   $(this).find(".depth2").stop().slideDown();
  // });
  //
  // var loc_page = location.pathname;
  // console.log(loc_page, loc_page.indexOf("management"))
  // if(loc_page.indexOf("management") != -1){
  //   $(".gnb > ul > li").eq(0).addClass("is_active");
  //   $(".gnb > ul > li").eq(0).children(".depth2").show();
  // }else if(loc_page.indexOf("investment") != -1){
  //   $(".gnb > ul > li").eq(1).addClass("is_active");
  //   $(".gnb > ul > li").eq(1).children(".depth2").show();
  // }else if(loc_page.indexOf("promote") != -1){
  //   $(".gnb > ul > li").eq(2).addClass("is_active");
  //   $(".gnb > ul > li").eq(2).children(".depth2").show();
  // }else if(loc_page.indexOf("adm") != -1){
  //   $(".gnb > ul > li").eq(3).addClass("is_active");
  //   $(".gnb > ul > li").eq(3).children(".depth2").show();
  // }


  var today = new Date()
  $('#singleDate').datepicker({
    showOn:'button',
    buttonImageOnly: true,
    buttonImage: buttonImage,
    dateFormat:"yy.mm.dd"
  });
  $('#singleDate').datepicker('setDate', today)
  //datepicker
  /*$("#date").datepicker({
    showOn:'button',
    buttonImageOnly: true,
    buttonImage: buttonImage,
    buttonText: "Select date",
    dateFormat:"yy.mm.dd",
    minDate:0,
    onSelect: function (date) {
      var endDate = $('#date2');
      var minDate = $(this).datepicker('getDate');
      minDate.setDate(minDate.getDate() + 1);
      endDate.datepicker('option', 'minDate', minDate);
    }
  });
  $("#date2").datepicker({
    showOn:'button',
    buttonImageOnly: true,
    buttonImage: buttonImage,
    buttonText: "Select date",
    dateFormat:"yy.mm.dd",
    minDate:0,
    onSelect: function (date) {
      var startDate = $('#date');
      var maxDate = $(this).datepicker('getDate');
      maxDate.setDate(maxDate.getDate() - 1);
      startDate.datepicker('option', 'maxDate', maxDate);
    }
  });*/

  //file custom
  var input_file = $("input[type='file']");
  input_file.each(function(){
    var fileTarget = $(this);
    fileTarget.on('change',function(){
      if(window.FileReader){
        var filename = $(this)[0].files[0].name;
      }else{
        var filename = $(this).val().split('/').pop().split('\\').pop();
      }
      $(this).siblings('.upload-name').val(filename);
    });
  });

  //number only
  $("input:text[numberOnly]").on("keyup", function() {
    $(this).val($(this).val().replace(/[^0-9]/g,""));
  });

  /*$('.close_modal').on('click', function (){
    $(this).closest('.modal_wrap').removeClass('is_show');
    isModal();
  })*/

  $('.tab_list .tab_item').on('click', function (){
    var t = $(this).data('target')
    $(this).parent().addClass('is_active').siblings().removeClass('is_active');
    $('#'+t).addClass('is_active').siblings().removeClass('is_active');
  })
});

