$(function (){
  var HeadCloseBtn = $('#HeadClose');
  var bottomCloseBtn = $('#close');
  var openBtn = $('#openModal');
  var modal = $('.modal_wrap');

  openBtn.click(function (){
    modal.addClass('is_show');
  })
  HeadCloseBtn.click(function (){
    modal.removeClass('is_show');
  })
  bottomCloseBtn.click(function (){
    modal.removeClass('is_show');
  })

  var url = window.location.href;
  function checkCurrentMenu(){
    $('.sub_wrap>ul>li').removeClass('is_current');
    if(url.indexOf('/vmd')>0){
      $('.gnb>ul>li:first-child .sub_wrap>ul>li:first-child').addClass('is_current');
    }else if(url.indexOf('/channel')>0){
      $('.gnb>ul>li:first-child .sub_wrap>ul>li:nth-child(2)').addClass('is_current');
    }
  }
  checkCurrentMenu();

  $('.sub_wrap li').on('click', function(){
    setTimeout(function(){
      url = window.location.href;
      checkCurrentMenu();
    }, 100)
  })


})